@mixin social-button {
  display: block;
  border: 0px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 19px;
  margin: 0.5rem 0;
  width: 100%;
  overflow: hidden;
  padding: 0px 10px;
  height: 50px;
}

.facebook-button {
  @include social-button;
  background-color: rgb(59, 89, 152);

  &:hover {
    background-color: rgb(41, 62, 105);
  }
}

.google-button {
  @include social-button;
  background: rgb(203, 63, 34);

  &:hover {
    background-color: rgb(165, 51, 28);
  }
}

