@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  overflow: hidden;
  position: relative;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

body {
  --primary-100: #ebf8ff;
  --primary-300: #90cdf4;
  --primary-500: #4a82b0;
  --primary-700: #2b6cb0;
  --primary-900: #2a4365;
  --red-100: #fff5f5;
  --red-300: #feb2b2;
  --red-500: #f56565;
  --red-700: #c53030;
  --red-900: #742a2a;
  --secondary: #a0aec0;
  --accent: #718096;
  --background: #fff;
  --black: #1c262f;
  --header: #2d3748;
  --height-header: 68px;
}

body {
  @apply bg-neutral-100 text-neutral-900;
}

.card {
  @apply border rounded-lg p-6 bg-white shadow-sm w-full;
}

@media only screen and (max-width: 640px) {
  .vw-90-on-phone {
    width: 90vw !important;
  }
  .vw-70-on-phone {
    width: 70vw !important;
  }
}

.padding-page {
  @apply p-8;
}
@layer components {
  .form-label {
    @apply block text-sm font-medium text-neutral-900;
  }

  .form-feedback {
    @apply mt-2 text-sm text-red-600 break-normal;
  }

  .form-input-icon {
    @apply h-5 w-5;
  }

  .form-input--error {
    @apply focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border rounded-md border-red-500;
  }

  .form-input {
    @apply focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-500 rounded-md;
  }

  .form-select-menu--error {
    @apply mt-1 block w-full pl-3 pr-10 py-2 text-base border-red-500 border focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md;
  }

  .form-select-menu {
    @apply mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-500 border focus:form-select-menu--focus sm:text-sm rounded-md;
  }

  .form-select-menu--focus {
    @apply outline-none ring-primary-500 border-primary-500;
  }
}
